import React, { useState, useContext, useEffect } from "react";
import { usePayafterDelivery } from "hooks/Forms/usePayafterDelivery";
import Loader from "components/loader";
import { useForm } from "react-hook-form";
import ThankYouPopup from "components/popup/thankYouPopup";
import DataProvider from "Context/DataContext";
import formCommonPostData from "utils/form-common-post-data";
import { useLocation } from "@reach/router";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

function QuoteSingleForm({
  setOpen,
  backgroundColor,
  textColour,
  text,
  buttonBg,
  placeholdertext,
  lgInputWidth,
}) {
  const { LocalObject } = useContext(DataProvider);
  const location = useLocation();
  const [thankOpen, setThankOpen] = useState(false);

  const { status, mutateAsync } = usePayafterDelivery();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const quote_id =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("quote_id")
      : "";

  const onSubmit = (data) => {
    const postData1 = {
      name: data.name,
      email: data.email,
      quote_id: quote_id,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((_) => {
      reset();
      setThankOpen(true);
      setOpen(true);
    });
  };

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        // if (window.dataLayer) {
        //   await window.dataLayer.push({ event: "optimize.activate" });
        // }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };

  const variant = useExperiment("mh1uxddoSiaUKKtGp-NUAg");

  return (
    <div
      style={{ backgroundColor }}
      className="grid md:grid-cols-2 w-full md:px-6 px-2 items-center justify-between md:flex-nowrap  md:mt-0 mt-6 rounded-md py-8 sm:py-4  lg:py-8 lg:px-10 gap-12 md:gap-4 lg:gap-0"
    >
      {variant == "1" ? (
        <>
          <div className="md:mb-0 mb-6">
            <p
              style={{ color: textColour }}
              className="text-[28px]  font-semibold font-secondary mb-2 text-center sm:text-left"
            >
              Pay <span className="text-orange">after delivery</span>
            </p>
            <p
              style={{ color: textColour }}
              className="text-sm sm:text-base  font-opensans text-center sm:text-left lg:pt-[5px]"
            >
              No need to pay now. Just send your name, email and we'll get to
              work.
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="flex flex-col sm:flex-row gap-3 pb-3 px-5 sm:px-0 items-center w-full gap-y-6">
              <div className="flex flex-col relative w-full sm:w-auto">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="md:w-[140px] font-opensans text-[14px]  rounded h-[45px] rounded-tl-lg rounded-bl-lg text-quotetext-200 px-3 text-xs lg:text-14 3xl:w-[250px] xl:w-[250px] 2xl:w-[185px] w-full border border-solid border-quotetext-100"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && (
                  <span className="text-red text-xs mb-[10px] font-sans mt-2 absolute top-[40px]">
                    {errorMessage}
                  </span>
                )}
              </div>
              <div className="flex flex-col relative w-full sm:w-auto">
                <input
                  type="email"
                  name="email"
                  id="txtEmail"
                  placeholder="Email address"
                  className="sm:w-[180px] font-opensans text-[14px] rounded h-[45px] rounded-tl-lg rounded-bl-lg text-quotetext-200 px-3 text-xs lg:text-14 3xl:w-[270px] xl:w-[270px] 2xl:w-[195px] w-full border border-solid border-quotetext-100"
                  {...register("email", {
                    required: true,
                    pattern: patternEmail,
                  })}
                />
                {errors.email && (
                  <span className="text-red text-xs mb-[10px] font-sans mt-2 absolute top-[40px]">
                    {errorMessageEmail}
                  </span>
                )}
              </div>
              <input type="hidden" id="zc_gad" name="zc_gad" value="" />
              <button className="bg-darkBlue text-white font-opensans font-bold text-[16px] w-full sm:w-[138px] h-[45px]">
                {status === "loading" ? <Loader /> : "SUBMIT"}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="md:mb-0 mb-6">
            <p
              style={{ color: textColour }}
              className="text-[28px] font-semibold font-secondary mb-2 text-center sm:text-left"
            >
              Pay <span className="text-orange">after delivery</span>
            </p>
            <p
              style={{ color: textColour }}
              className="text-sm sm:text-base  font-opensans text-center sm:text-left lg:pt-[5px]"
            >
              {text}
            </p>
          </div>

          <form
            className="flex  md:justify-center md:w-[unset] w-full justify-between"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex-col w-full md:w-[unset]">
              <div className="div w-full md:w-[unset]">
                <input
                  type="text"
                  className={`h-[45px] rounded-tl-lg rounded-bl-lg text-quotetext-200 px-3 text-xs lg:text-14 w-full  lg:${lgInputWidth}  border border-solid border-quotetext-100`}
                  placeholder={placeholdertext}
                  name="email"
                  {...register("email", {
                    required: true,
                  })}
                />
              </div>
              <div>
                {errors.email && (
                  <span className="text-red text-xs">
                    This field is required
                  </span>
                )}
              </div>
            </div>

            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <button
              style={{ background: buttonBg }}
              className="h-[45px] px-6  text-white rounded-tr-lg rounded-br-lg font-opensans font-bold"
            >
              {status === "loading" ? <Loader /> : "SUBMIT"}
            </button>
          </form>
        </>
      )}

      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </div>
  );
}

export default QuoteSingleForm;
