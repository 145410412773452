import SEO from "components/seo";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Social from "../Social";
import TranslatorHubHeader from "../TranslatorHubHeader/TranslatorHubHeader";
import FormMobile from "../FormMobile";
import HubComments from "./HubComments";
import Footer from "components/layout/footer";
import Author from "../../../assets/Author.png";
import Copy from "../../../assets/copy.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Facebook, Linkedin, Twitter } from "react-social-sharing";
import loadable from "@loadable/component";
import Form from "./getForm";
import RegisterQuote from "./registerQuote";
import QuoteSingleForm from "components/Quote/firstComp/QuoteSingleForm";
import AiTools from "../AiTools";

const AuthorSection = loadable(() => import("./AuthorSection"));

const Index = (props) => {
  const [paddingTop, setPaddingTop] = useState(false);
  const [hasLoadedFromScript, setHasLoadedFromScript] = useState(true);
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [url, setUrl] = useState("");
  const {
    pageContext: {
      data: { data },
    },
  } = props;

  const project_id = data.seo_project_id;
  const scriptAlreadyExists = () =>
    document.querySelector("script#alert-script") !== null;

  const appendDynamicFormSubmitScript = () => {
    var inlineScript = document.createTextNode(
      "function handleForm(){function getCookie(cookieName){let name = cookieName + '=';let ca = document.cookie.split(';');for (let i = 0; i < ca.length; i++) {let c = ca[i];while (c.charAt(0) === ' ') {c = c.substring(1);};if (c.indexOf(name) === 0) {return c.substring(name.length, c.length);};};return 'not set';};var email=document.getElementById('midemail').value;const response=fetch('https://admin.tomedes.com/api/v1/submit-subscribe',{method:'POST',body:JSON.stringify({'email':email, pageLink: location.href, source: getCookie('utm_source'), utm_campaign: getCookie('utm_campaign'), utm_agid: getCookie('utm_agid'), utm_term: getCookie('utm_term'), creative: getCookie('creative'), device: getCookie('device'), placement: getCookie('placement'), utm_medium: getCookie('utm_medium'),}),headers:{'Content-Type':'application/json'}}).then(data=>{return data.json();}).then(resp=>{if(resp.success===1){document.getElementById('midemail').value='';document.getElementById('orangeForm').style.display='none';document.getElementById('orangeThankyou').style.display='block';document.getElementById('closeThankyou').addEventListener('click',function(){document.getElementById('orangeThankyou').style.display='none';document.getElementById('orangeForm').style.display='block';})}else{}});}"
    );
    const script = document.createElement("script");
    script.id = "alert-script";
    script.async = true;
    script.appendChild(inlineScript);
    setHasLoadedFromScript(true);
    document.body.append(script);
  };

  useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendDynamicFormSubmitScript();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setUrl(window.location.href);
    }
  }, []);

  //ab testing

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        if (window.dataLayer) {
          await window.dataLayer.push({ event: "optimize.activate" });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };

  // const variant = useExperiment("H7kig_KJSLakHgOri2vIVQ");
  const variant = 1;

  if (variant) {
    setPaddingTop(true);
  }

  // useEffect(() => {
  //   if (hasLoadedFromScript === true) {
  //     let ul;
  //     let uls = document.getElementsByTagName("p");

  //     if (uls.length > 10) {
  //       ul = document.getElementsByTagName("p")[10];
  //     } else {
  //       ul = document.getElementsByTagName("p")[uls.length - 1];
  //     }

  //     if (variant == 1) {
  //       const form =
  //         "<div id='orangeForm' class='orangeForm orange-form-old classImp w-full bg-orangeFormBgMobile sm:bg-orangeFormBgDektop bg-cover bg-center py-11 px-6 sm:px-11  text-white mt-10 mb-8'><h2 class='font-secondary text-white text-17 sm:text-[33px] font-bold text-center sm:text-left'><span class='block'>Broaden Your Horizons</span><span class='block'>With International Strategy</span><span class='block'>and Local Knowledge</span></h2><p class='font-opensans font-semibold text-14 sm:text-[20px] text-left my-5'>Subscribe to Our Business Newsletter!</p><form  onsubmit='handleForm();return false' class='flex flex-col sm:flex-row gap-3'><input type='email' name='midemail' id='midemail' placeholder='Please enter a valid business email' required class='text-black font-opensans font-light px-2 py-2 w-full md:w-8/12 2xl:w-5/12' /><button type='submit' class='bg-[#001546] hover:bg-[#0032A7] font-opensans font-semibold text-14 text-[#E6E6E6] py-2 px-8 transition-colors duration-300'>Submit</button></form></div><div id='orangeThankyou' class=' hidden w-full thankyou classImp2 bg-orangeFormBgMobile sm:bg-orangeFormBgDektop bg-cover bg-center py-20 sm:py-24 px-8 sm:px-11  text-white mt-10 mb-8'><h2 class='font-secondary text-[32px] sm:text-46 font-bold mb-5 text-center sm:text-left'>Thank you!</h2><p class='font-opensans font-semibold text-17 sm:text-[20px] text-center sm:text-left my-5'>Please check your inbox for a confirmation email.</p><button id='closeThankyou' class='bg-[#001546] hover:bg-[#0032A7] font-opensans font-semibold text-14 text-[#E6E6E6] py-2 px-8 transition-colors duration-300 sm:hidden w-full'>Close</button></div>";
  //       ul.innerHTML += form;
  //     }
  //     if (variant == 1) {
  //       let oldForm = document.getElementById("orangeForm");
  //       let style = (oldForm.style.display = "block");
  //       style += oldForm;
  //     }
  //   }
  // }, [hasLoadedFromScript, variant]);
  // console.log(data);

  const handleShow = () => {
    window.scrollY ? setShow(true) : setShow(false);
  };

  // Fixed the Parsing issue of nested List
  useEffect(() => {
    let lis = document.getElementsByTagName("li");
    const arrayOfLis = Object.values(lis);
    const filteredArray = arrayOfLis.filter((item) => item.ariaLevel === "2");
    filteredArray.forEach((item) => item.classList.add("parsing-bug-fix"));
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleShow);
  //   return () => window.removeEventListener("scroll", handleShow);
  // }, []);

  // Your existing code...

  // Identify the position to insert the QuoteSingleForm component
  // const insertionPointIndex =
  //   data.seo_project_content.indexOf("</p>") + "</p>".length;
  const nthOccurrence = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };

  const insertionPointIndex =
    nthOccurrence(data.seo_project_content, "</p>", 2) + "</p>".length;

  // console.log(insertionPointIndex, "insertionpoint");
  const contentBeforeInsertion = data.seo_project_content.slice(
    0,
    insertionPointIndex
  );
  // console.log(contentBeforeInsertion, "contentbefor");
  const contentAfterInsertion =
    data.seo_project_content.slice(insertionPointIndex);
  // console.log(contentAfterInsertion, "contentafter");
  const BackgrounColour = {};

  return (
    <React.Fragment>
      {/* {show && (
        <div className="top-0 sticky lg:top-[-1px] bg-[#00338E] text-white z-[999]  text-center text-sm px-4 py-2">
          Make translation as easy as ABC with our MT comparison tool. Read more{" "}
          <Link
            to="/translator-hub/game-changing-translation-mt-engine-comparator"
            rel="nofollow"
            className="review_title text-normal"
          >
            here.
          </Link>
        </div>
      )} */}
      <TranslatorHubHeader
        title="Translator's Blog"
        styleOne={variant}
        show={show}
      />
      <SEO
        title={data.seo_project_title || data.title_x || data.seo_h1}
        description={data.seo_project_meta_desc}
        keywords={data.seo_project_meta_keywords}
        slug={data.slug}
        banner={data.cover_image}
        createdAt={data.seo_project_create_date}
        modifiedAt={data.seo_project_modify_date}
        author={data.author_name}
        genre={data?.genre}
        wordCount={data?.word_count}
      />
      <div
        className={`w-11/12 sm:w-10/12 md:w-11/12 md:px-3 md:max-w-2xl lg:max-w-none xl:max-w-7xl mx-auto lg:w-full  mb-24 mt-0 md:mt-24 ${
          show && "pt-[30px]"
        }`}
      >
        <div className="relative  flex flex-col lg:flex-row lg:gap-20 max-w-370 sm:max-w-full mx-auto sm:mx-0">
          <div className="col-span-2  ">
            <h1 className="text-[28px] pb-6 text-blackNew-600 font-semibold mt-10">
              {data.seo_h1}
            </h1>
            <div className="my-6 text-blackNew-700">
              <i className="text-blackNew-700">
                {data.seo_project_modify_date
                  ? moment(data.seo_project_modify_date).format("MMMM D, YYYY")
                  : moment(data.seo_project_create_date).format("MMMM D, YYYY")}
              </i>

              {/* <i className="text-blackNew-700">
                Date Posted :{" "}
                {moment(data.seo_project_create_date).format("MMMM D, YYYY")}
              </i>{" "}
              |{" "}
              <i className="text-blackNew-700">
                Last Updated :{" "}
                {moment(data.seo_project_modify_date).format("MMMM D, YYYY")}
              </i> */}
            </div>
            <OptimizedImage
              src={data.cover_image}
              alt={data.seo_h1}
              lazyload={false}
              className="w-[343px] sm:w-full xl:w-full object-cover h-[216px] sm:h-auto lg:h-[370px] max-h-80 mb-6"
            />
            {/* <div
              className="mb-4 translator_hub_details [p:nth-child(3n+1)]"
              dangerouslySetInnerHTML={{ __html: data.seo_project_content }}
            /> */}
            <div
              className="mb-4 translator_hub_details [p:nth-child(3n+1)]"
              dangerouslySetInnerHTML={{ __html: contentBeforeInsertion }}
            />
            {/* <div
              className=" my-6"
              style={{
                boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <QuoteSingleForm
                setOpen={setShow}
                backgroundColor="#00173A"
                textColour="#FFFFFF"
                buttonBg="#FF7B16"
                placeholdertext="Enter your email"
                text="No need to pay now. Just send your email and we’ll get back to you in 30 minutes to know more details."
                lgInputWidth="w-48"
              />
            </div> */}

            <div
              className="mb-4 translator_hub_details [p:nth-child(3n+1)]"
              dangerouslySetInnerHTML={{ __html: contentAfterInsertion }}
            />

            {data.author_name &&
              data.author_description &&
              data.author_image && (
                <div className="flex justify-center w-full py-8">
                  <div className="flex flex-col items-center text-[#828282] leading-[30px]">
                    <img
                      src={data.author_image}
                      className="w-32 h-32 object-cover rounded-full"
                    />
                    <h5 className="text-[26px] text-black font-bold my-4">
                      By {data.author_name}
                    </h5>
                    <p className="text-left">{data.author_description}</p>
                    <p className="text-xl text-black my-2">Share:</p>
                    <div className="flex gap-4 items-center">
                      <Twitter
                        solid
                        small
                        link={url}
                        style={{
                          borderRadius: "50%",
                          width: "36px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#3D948E",
                        }}
                      />
                      <Facebook
                        solid
                        small
                        link={url}
                        style={{
                          borderRadius: "50%",
                          width: "36px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#3D948E",
                        }}
                      />
                      <Linkedin
                        solid
                        small
                        link={url}
                        style={{
                          borderRadius: "50%",
                          width: "36px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#3D948E",
                        }}
                      />
                      <div
                        style={{
                          background: "#000",
                          borderRadius: "50%",
                          width: "36px",
                          height: "36px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                          setShowText(true);
                          setTimeout(() => {
                            setShowText(false);
                          }, 500);
                        }}
                      >
                        <img src={Copy} className="w-4 h-4" />
                      </div>
                      <span
                        className={`text-green-500 text-sm ${
                          showText ? "block" : "hidden"
                        }`}
                      >
                        Copied to clipboard
                      </span>
                    </div>
                  </div>
                </div>
              )}
            <div className="lg:hidden py-3 flex justify-center md:justify-start"></div>
            {!paddingTop && (
              <div className="sm:hidden">
                <FormMobile marginX="0" />
              </div>
            )}
            <HubComments data={data} project_id={project_id} />
          </div>

          <div className="sticky h-max  w-full top-24 ">
            {/* <div>
              <h5 className="font-bold text-newGray text-new mt-4 lg:mt-10">
                CATEGORIES
              </h5>
              <ul className="text-newGray">
                {props.pageContext.uniqueCategories.map((link, index) => (
                  <li
                    className="text-new py-2 hover:text-newLightBlue cursor-pointer"
                    key={index}
                  >
                    <Link to={`/translator-hub.php/category/${link}`}>
                      {link}
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
            {/* <div className="line border-b border-gray mt-12"></div> */}
            {/* <div className="hidden lg:block"> */}
            {/* <Form marginX="0" paddingTop={paddingTop}/> */}
            {/* </div> */}
            {/* <Social /> */}
            {/* <div className="line border-b border-gray mt-12"></div> */}
            <div className="mr-0">
              <Form />
              <AiTools />
            </div>
          </div>
        </div>
        <div className="mt-8">
          <RegisterQuote />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Index;
